import s from './OrderSummary.module.scss';

type Props = {
  toBeBilledToday?: string;
  couponSection: React.ReactElement;
  finalizeButton: React.ReactElement;
  orderItems: React.ReactElement[];
};

const PlatformInducement = () => (
  <section className={s.PlatformInducement}>
    <strong>W subskrypcji otrzymasz:</strong>
    <ul>
      <li>Jogę, medytację i pilates (800+ sesji)</li>
      <li>Gotowe Plany Praktyki co miesiąc</li> 
      <li>60+ motywujących wyzwań</li>
      <li>50+ najlepszych nauczycieli w Polsce</li>
      <li>Kursy i programy od podstaw</li>
      <li>Zajęcia na 5 poziomach zaawansowania</li>
      <li>Różne style jogi</li>
    </ul>
  </section>
);

const OrderSummary: React.FC<Props> = ({
  toBeBilledToday,
  couponSection,
  finalizeButton,
  orderItems,
}) => (
  <section className={s.Summary}>
    <h5 className={s.Heading}>Zamówienie</h5>
    <ul className={s.Items}>
      {orderItems}
    </ul>
    <PlatformInducement />
    <div className={s.Box}>
      <h5 className={s.Heading}>Podsumowanie</h5>
      <div>{couponSection}</div>
      <div className={s.BilledToday}>
        <span className={s.PriceItemName}>Zaksięgowane dzisiaj</span>
        <span className={s.PriceItemAmount}>{toBeBilledToday}</span>
      </div>
      <div className={s.ButtonWrapper}>
        {finalizeButton}
      </div>
    </div>
  </section>
);

export default OrderSummary;
