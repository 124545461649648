import { useHistory } from 'react-router-dom';
import { useCallback } from 'react';

import { AuthService, AuthTokenService, UsersService } from 'src/services';
import { useCreatePaymentMethod, useConfirmCardSetup } from 'src/features/StripeCardForm';
import { SubscriptionProduct } from 'src/models/SubscriptionProduct';
import { useUserContext } from 'src/contexts/UserContext/context';
import { GTMEvent } from 'src/models/GoogleTagManager';
import { sendEcommerceEvent } from './useTrackSignUpEvent';

import { useSignUpContext } from '../context';
import { SignUpError } from '../context/types';
import { useWelcomeViewContext } from 'src/contexts/WelcomeViewContext';
import { StripeError } from 'src/errors';

const composeWelcomeLink = (product: SubscriptionProduct) =>
  `/welcome?amount=${product.price}&currency=PLN`;

export const useSignUp = () => {
  const [{
    couponCode,
    product,
    userDetails,
    consents,
    validUserDetailsProvided,
    paymentMethodRequired,
  }, dispatchSignUpAction] = useSignUpContext();
  const [, dispatchUserAction] = useUserContext();
  const createPaymentMethod = useCreatePaymentMethod();
  const confirmCardSetup = useConfirmCardSetup();
  const history = useHistory();
  const { toggleWelcomeView } = useWelcomeViewContext();

  return useCallback(async () => {
    if (!product) {
      dispatchSignUpAction({ type: 'setError', error: SignUpError.UnknownError });
      return;
    }

    dispatchSignUpAction({ type: 'signUpStarted' });
    if (!validUserDetailsProvided || !consents.termsAndConditions) {
      return;
    }

    const paymentMethod = paymentMethodRequired
      ? await createPaymentMethod()
      : undefined;

    const registerDto = {
      name: [userDetails.firstName, userDetails.lastName].join(' ').trim(),
      email: userDetails.email,
      password: userDetails.password,
      paymentMethodId: paymentMethod?.id,
      subscriptionProductId: product.id,
      couponCode,
      termsAndConditionsConsent: consents.termsAndConditions,
      marketingConsent: consents.marketing,
      profilingConsent: consents.profiling,
    };

    try {
      let result = await AuthService.register(registerDto);
      if (result.requires3DS) {
        const { setupIntent, error } = await confirmCardSetup(result.clientSecret);
        if (error || setupIntent?.status !== 'succeeded') {
          throw new StripeError(new Error('Could not complete 3D Secure authorization'));
        }
        result = await AuthService.register({ ...registerDto, setupIntentId: setupIntent.id });
      }
      AuthTokenService.set(result.accessToken);
      const [user, isEligibleForCoupons] = await Promise.all([UsersService.getMe(), UsersService.couponEligibility()]);
      dispatchUserAction({ type: 'logInSuccess', payload: { ...user, isEligibleForCoupons } });
      toggleWelcomeView(true);
      sendEcommerceEvent(GTMEvent.Purchase, {
        product,
        user,
        discount_info: couponCode,
      });
      history.replace(composeWelcomeLink(product!));
      dispatchSignUpAction({ type: 'signUpSuccess' });
    } catch (error: any) {
      dispatchSignUpAction({ type: 'signUpError', error });
    }
  }, [
    couponCode,
    userDetails,
    product,
    consents,
    createPaymentMethod,
    paymentMethodRequired,
    confirmCardSetup,
    dispatchUserAction,
    dispatchSignUpAction,
    history,
    validUserDetailsProvided,
    toggleWelcomeView,
  ]);
};
