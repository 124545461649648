import { useEffect, useState } from 'react';

import { useDebouncedValue } from 'src/hooks/useDebounce';
import { usePreviousValue } from 'src/hooks';
import { useUserActions, useUserState } from 'src/contexts/UserContext';

import { useSubscriptionProducts } from './hooks';
import SubscriptionBox from './SubscriptionBox';
import SubscriptionOption from './SubscriptionOption';
import Confirmation from './Confirmation';
import {
  Container,
  MainLoader,
  ContainerLabel,
  EditModeToggle,
  Subscriptions,
  CurrentSubscription,
  AvailableSubscriptions,
  SubscriptionChangeInfo,
  UpcomingSubscriptionInfo,
  PendingCancellationInfo,
  TriggerPayment,
  PaymentSuccessInfo,
  Footer,
  NextEventDate,
  NextEventLabel,
  Submit,
} from './styles';
import { SubscriptionStatus } from 'src/models/Subscription';
import { SubscriptionProduct } from 'src/models/SubscriptionProduct';
import { User } from 'src/models/User';
import { PurchaseElement } from 'src/views/SignUp/OneTimePayment/SinglePayment';

function checkIfCanTriggerPayment({ subscription }: User, _product: SubscriptionProduct | null | undefined) {
  if (subscription == null) {
    return false;
  }
  const canPay = [
    SubscriptionStatus.Canceled,
    SubscriptionStatus.Unpaid,
    SubscriptionStatus.PastDue,
  ].includes(subscription.status);
  return canPay && subscription.subscriptionProduct?.hidden === false;
}

export const SubscriptionPicker: React.FC = () => {
  const [isEditMode, setIsEditMode] = useState(false);
  const showEditMode = useDebouncedValue(isEditMode, { debounceDelay: 400 });

  const subscriptions = useSubscriptionProducts();

  const {
    user,
    user: { subscription, coupon },
    nextPaymentAt,
    changeSubscriptionStatus,
    undoCancelSubscriptionStatus,
    isCancellationPending,
    manualPaymentStatus,
  } = useUserState();
  const isEligibleForCoupons = user.isEligibleForCoupons;
  const cancelsAt = subscription?.cancelAt;
  const currentPeriodEnd = subscription?.currentPeriodEnd;
  const isRenewable = subscription?.subscriptionProduct?.isRenewable;
  const subscriptionStatus = subscription?.status;
  const prevChangeSubscriptionStatus = usePreviousValue(changeSubscriptionStatus);
  const currentSubscription = subscription?.subscriptionProduct;
  const nextSubscription = subscription?.nextSubscriptionProduct;
  const purchasedProduct = nextSubscription ?? currentSubscription;
  const {
    changeSubscription,
    undoCancelSubscription,
    triggerManualPayment,
    openCardForm
  } = useUserActions();

  const [draftSubscription, setDraftSubscription] = useState(purchasedProduct);
  const draftSubscriptionName = draftSubscription?.name ?? '<nieznana>';
  useEffect(
    () => setDraftSubscription(purchasedProduct),
    [showEditMode, purchasedProduct]
  );

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showPurchaseElement, setShowPurchaseElement] = useState(false);

  const canSaveChanges = draftSubscription?.id !== purchasedProduct!.id;

  const onConfirmChange = () => {
    if (!draftSubscription) return;
    setShowConfirmation(false);
    changeSubscription(draftSubscription.id);
  };

  const onTriggerPayment = () => {
    if (draftSubscription?.endBehaviour === 'one_time') {
      setShowPurchaseElement(true);
    } else {
      triggerManualPayment();
    }
  };

  const onConfirmationClick = () => {
    if (draftSubscription?.endBehaviour === 'one_time') {
      setShowPurchaseElement(true);
    } else if (user.cardSuffix == null) {
      openCardForm();
    } else {
      setShowConfirmation(true);
    }
  };

  useEffect(() => {
    if (changeSubscriptionStatus === 'idle' || prevChangeSubscriptionStatus === 'pending') {
      setIsEditMode(false);
    }
  }, [changeSubscriptionStatus, prevChangeSubscriptionStatus]);

  const hasActiveSubscription = [SubscriptionStatus.Active, SubscriptionStatus.Trialing].includes(subscriptionStatus!);
  const canResumeCurrentProduct = currentSubscription?.hidden === false;

  const showCurrentProduct = hasActiveSubscription || canResumeCurrentProduct;

  const canTriggerPayment = checkIfCanTriggerPayment(user, draftSubscription);

  if (showPurchaseElement) {
    return <PurchaseElement product={draftSubscription!} onCancel={() => setShowPurchaseElement(false)} />
  }

  return (
    <Container isActive={showEditMode}>
      {!subscriptions && <MainLoader />}
      <ContainerLabel>
        {hasActiveSubscription ? 'Aktywny plan' : 'Wybrany plan'}
      </ContainerLabel>
      <EditModeToggle onClick={() => setIsEditMode(!isEditMode)}>
        {isEditMode ? 'Anuluj' : (showCurrentProduct ? 'Edytuj plan' : 'Wybierz plan')}
      </EditModeToggle>
      <Subscriptions>
        <CurrentSubscription isHidden={isEditMode}>
          {(showCurrentProduct && currentSubscription) ? (
            <SubscriptionBox
              subscription={currentSubscription}
              coupon={coupon}
              isEligibleForCoupons={isEligibleForCoupons}
            />
          ) : (<div>brak aktywnej subskrypcji</div>)}
        </CurrentSubscription>
        <AvailableSubscriptions in={showEditMode}>
          <SubscriptionChangeInfo message="Zmiana planu nie wpływa na aktywne kody rabatowe." />
          {(subscriptions ?? []).map(subscription => (
            <SubscriptionOption
              key={subscription.id}
              subscription={subscription}
              coupon={coupon}
              isEligibleForCoupons={isEligibleForCoupons}
              checked={subscription.id === draftSubscription?.id}
              onSelect={setDraftSubscription}
              disabled={false}
            />
          ))}
          <Submit
            label="Potwierdź zmiany"
            onClick={onConfirmationClick}
            disabled={!canSaveChanges}
            pending={changeSubscriptionStatus === 'pending'}
          />
        </AvailableSubscriptions>
      </Subscriptions>
      <Footer>
        {nextSubscription && (
          <UpcomingSubscriptionInfo
            message={`Wybrano: ${nextSubscription.name} po zakończeniu aktywnego okresu rozliczeniowego`}
            delay={1000}
          />
        )}
        <PaymentSuccessInfo />
        {nextPaymentAt && (
          <>
            <NextEventLabel>Kolejna płatność:</NextEventLabel>
            <NextEventDate>
              {nextPaymentAt ? nextPaymentAt.toLocaleDateString() : 'nie dotyczy'}
            </NextEventDate>
          </>
        )}
        {(!nextPaymentAt && !isRenewable && currentPeriodEnd && hasActiveSubscription) && (
          <>
            <NextEventLabel>Plan aktywny do:</NextEventLabel>
            <NextEventDate>
              {currentPeriodEnd.toLocaleDateString()}
            </NextEventDate>
          </>
        )}
        {isCancellationPending && cancelsAt && (
          <>
            <NextEventLabel>Subskrypcja wygasa:</NextEventLabel>
            <NextEventDate>{cancelsAt.toLocaleDateString()}</NextEventDate>
            <PendingCancellationInfo
              message="Subskrypcja została anulowana. Jeżeli chcesz zmienić zdanie, kliknij tutaj."
              delay={1000}
              onClick={undoCancelSubscription}
              pending={undoCancelSubscriptionStatus === 'pending'}
            />
          </>
        )}
        {canTriggerPayment && (
          <TriggerPayment
            mode={manualPaymentStatus === 'error' ? 'error' : 'warning'}
            message={manualPaymentStatus === 'error'
              ? 'Nie udało się dokonać płatności. Kliknij tutaj, aby spróbować ponownie.'
              : 'Kliknij tutaj, aby dokonać płatności.'}
            delay={1000}
            onClick={onTriggerPayment}
            pending={manualPaymentStatus === 'pending'}
          />
        )}
      </Footer>
      <Confirmation
        newSubscriptionTitle={draftSubscriptionName}
        open={showConfirmation}
        onCancel={() => setShowConfirmation(false)}
        onConfirm={onConfirmChange}
        timing={hasActiveSubscription ? 'onBillingPeriodEnd' : 'immediately'}
        showProductNotActiveWarn={purchasedProduct?.active !== true}
      />
    </Container>
  );
};
