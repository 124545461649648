import React from 'react';
import ReactDOM from 'react-dom';

import { UserProvider } from 'src/contexts/UserContext';
import { ErrorReportingService, GTMService, MixpanelService, PinterestPixelService } from 'src/services';
import MainRouter from './MainRouter';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { ErrorBoundary as AppErrorBoundary } from './errors';

ErrorReportingService.init();

ReactDOM.render(
  <React.StrictMode>
    <AppErrorBoundary>
      <UserProvider>
        <MainRouter />
      </UserProvider>
    </AppErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

GTMService.init();
MixpanelService.init();
PinterestPixelService.init();

serviceWorkerRegistration.register();
