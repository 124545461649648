import styled from 'styled-components';

const minHorizontalLayoutWidth = 1100;

const layout = {
  vertical: `@media (max-width: ${minHorizontalLayoutWidth - 1}px)`,
  horizontal: `@media (min-width: ${minHorizontalLayoutWidth}px)`,
};

const Wrapper = styled.div`
  ${layout.horizontal} {
    display: grid;
    height: calc(100% + 2 * 48px);
    grid-template-columns: minmax(0, 1fr) 435px;
    grid-template-rows: 1fr minmax(0, auto);
    margin-top: -48px;
    margin-bottom: -48px;
  }
  ${layout.vertical} {
    display: flex;
    flex-direction: column;
  }
`;

// Desktop: left, top, above secondary content
// Mobile: 1st item from top
const MainContentArea = styled.div`
  ${layout.horizontal} {
    grid-column: 1;
    border-right: ${p => p.theme.border};
  }
  ${layout.vertical} {
    order: 1;
  }
`;

const MainContentAreaInner = styled.div`
  ${layout.horizontal} {
    margin: 48px 0;
    position: sticky;
    top: ${80 + 48}px;
    min-height: calc(100% - 48px - 48px);
  }
  ${layout.vertical} {
  }
`;

// Desktop: left, bottom, below primary content
// Mobile: 3rd item from top
const SecondaryContentArea = styled.div`
  ${layout.horizontal} {
    grid-column: 1;
    grid-row: 2;
    border-right: ${p => p.theme.border};
  }
  ${layout.vertical} {
    order: 2;
    margin-top: 16px;
  }
`;

const SecondaryContentAreaInner = styled.div`
  ${layout.horizontal} {
    margin: -48px 0 48px 0;
  }
  ${layout.vertical} {
  }
`;

// Desktop: right & sticky
// Mobile: 2nd item from top
const WidgetArea = styled.div`
  ${layout.horizontal} {
    grid-column: 2;
    grid-row: 1 / span 2;
    margin-left: 24px;
  }
  ${layout.vertical} {
    order: 3;
    margin-top: 36px;
  }

`;

const WidgetAreaInner = styled.div`
  ${layout.horizontal} {
    margin: 48px 0;
    position: sticky;
    top: ${80 + 48}px;
  }
  ${layout.vertical} {
  }
`;

const ContentWithMargin = styled.div`
  ${layout.horizontal} {
    margin-right: 24px;
  }
`;

const ContentWithoutMargin = styled.div``;

const WidgetLayout: React.FC & {
  MainContentArea: React.ComponentType;
  SecondaryContentArea: React.ComponentType;
  WidgetArea: React.ComponentType;
  ContentWithMargin: React.ComponentType;
  ContentWithoutMargin: React.ComponentType;
  mediaQueries: typeof layout;
} = ({ children }) => <Wrapper>{children}</Wrapper>

WidgetLayout.MainContentArea = ({ children }) => (
  <MainContentArea>
    <MainContentAreaInner>
      {children}
    </MainContentAreaInner>
  </MainContentArea>
);


WidgetLayout.SecondaryContentArea = ({ children }) => (
  <SecondaryContentArea>
    <SecondaryContentAreaInner>
      {children}
    </SecondaryContentAreaInner>
  </SecondaryContentArea>
);

WidgetLayout.WidgetArea = ({ children }) => (
  <WidgetArea>
    <WidgetAreaInner>
      {children}
    </WidgetAreaInner>
  </WidgetArea>
);

WidgetLayout.ContentWithMargin = ContentWithMargin;
WidgetLayout.ContentWithoutMargin = ContentWithoutMargin;

WidgetLayout.mediaQueries = layout;

export { WidgetLayout };
