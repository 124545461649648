import { useEffect, useState } from 'react';
import { Button, InfoBox, Modal } from 'src/components';

import { ActionButton } from '../../styles';
import { useUserActions, useUserState } from 'src/contexts/UserContext';
import { usePreviousValue } from 'src/hooks';
import { useBarecancel } from 'src/services';

export const CancelSubscription: React.FC = () => {
  const [showDialog, setShowDialog] = useState(false);
  const { cancelSubscription } = useUserActions();
  const {
    cancelSubscriptionStatus: status,
    isCancellationPending,
    isTrialing,
    user,
  } = useUserState();
  const openBarecancel = useBarecancel((user as unknown as {stripeId: string}).stripeId);

  const productNotActive = user.subscription?.subscriptionProduct?.active !== true;

  const prevStatus = usePreviousValue(status);
  useEffect(() => {
    if (status === 'idle' && prevStatus === 'pending') {
      setShowDialog(false);
      // if (isCancellationPending) {
      //   openBarecancel();
      // }
    }
  }, [status, prevStatus, isCancellationPending, openBarecancel]);

  return (
    <>
      {!isCancellationPending && (
        <ActionButton onClick={() => setShowDialog(true)}>
          Anuluj subskrypcję
        </ActionButton>
      )}
      <Modal open={showDialog}>
        <Modal.Title>Potwierdź anulowanie subskrypcji</Modal.Title>
        <Modal.Content>
          {isTrialing && (
            <>
              <p>Czy na pewno chcesz anulować okres próbny swojej subskrypcji?</p>
              <p>Utracisz dostęp do materiałów na naszej platformie, a także utracisz swój postęp.</p>
            </>
          )}

          {!isTrialing && (
            productNotActive ?
            <>
              <p><strong>UWAGA: Obecnie korzystasz z abonamentu, który nie jest już dostępny w naszej stałej ofercie. Anulacja subskrypcji uniemożliwi w przyszłości powrót do obecnej niższej ceny. Dopóki nie przerwiesz subskrypcji, możesz cieszyć się preferencyjną ceną. Sprawdź nasz aktualny cennik.</strong></p>
              <p>Czy nadal chcesz anulować subskrypcję?</p>
              <p>Po zakończeniu obecnego okresu rozliczeniowego utracisz dostęp do materiałów na naszej platformie, a także utracisz swój postęp.</p>
            </>
            :
            <>
              <p>Czy na pewno chcesz anulować swoją subskrypcję?</p>
              <p>Po zakończeniu obecnego okresu rozliczeniowego utracisz dostęp do materiałów na naszej platformie, a także utracisz swój postęp.</p>
            </>
          )}
          
          {status === 'error' && (
            <InfoBox
              mode="error"
              message="Anulowanie subskrypcji nie powiodło się."
            />
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            label="Powrót"
            onClick={() => setShowDialog(false)}
            variant="minimal"
            color="primary"
            disabled={status === 'pending'}
          />
          <Button
            label={productNotActive ? 'Anuluj mimo wszystko' : 'Anuluj subskrypcję'}
            onClick={cancelSubscription}
            disabled={status === 'pending'}
            pending={status === 'pending'}
          />
        </Modal.Actions>
      </Modal>
    </>
  );
};
