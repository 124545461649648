import { isNativePlatform } from 'src/config';
import Cookies from 'js-cookie';
export const AUTH_TOKEN_KEY = 'PY_ACCESS_TOKEN';

const AuthTokenService = {
  get(): string | null {
    if (isNativePlatform) {
      return localStorage.getItem(AUTH_TOKEN_KEY) ?? null;
    }
    return Cookies.get(AUTH_TOKEN_KEY) ?? null;
  },

  set(token: string) {
    if (isNativePlatform) {
      localStorage.setItem(AUTH_TOKEN_KEY, token);
      return;
    }
    Cookies.remove(AUTH_TOKEN_KEY);
    Cookies.set(AUTH_TOKEN_KEY, token);
  },

  clear() {
    if (isNativePlatform) {
      localStorage.removeItem(AUTH_TOKEN_KEY);
      return;
    }
    Cookies.remove(AUTH_TOKEN_KEY);
  },
};

export default AuthTokenService;
