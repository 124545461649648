import { useCallback, useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { useBackNavigationInfo } from 'src/hooks';

import { ChallengesService, ErrorReportingService } from 'src/services';
import { ChallengeId, ChallengeSessionId, ChallengeStepId } from 'src/models/Challenge';
import ChallengessService from 'src/services/ChallengesService';
import { useUserState } from 'src/contexts/UserContext';

export const useChallenge = (id?: ChallengeId, challengeSessionId?: ChallengeSessionId) => {
  const { isRegularUser } = useUserState();
  const queryClient = useQueryClient();
  console.log(queryClient.getQueryData(['challenges', id]));
  return useQuery(
    ['challenges', id ?? challengeSessionId],
    () => ChallengesService.getByIdV2((id ?? challengeSessionId)!, {
      excludeUnpublishedSteps: isRegularUser,
      bySession: challengeSessionId != null,
    }),
    { refetchOnWindowFocus: false },
  );
}

export const useChallengeSession = (id: ChallengeSessionId) => useQuery(
  ['challengeSessions', id],
  () => ChallengesService.getSessionById(id),
  { refetchOnWindowFocus: false },
);

export const useToggleStepCompleted = () => {
  const queryClient = useQueryClient();
  return useCallback((
    challengeId: ChallengeId,
    stepId: ChallengeStepId,
    status: boolean,
  ) => {
    ChallengesService.toggleChallengeStepCompleted(stepId, status)
      .then(() => queryClient.invalidateQueries(['challenges', challengeId]))
      .catch(ErrorReportingService.report);
  }, [queryClient]);
};

export const useReturnLink = () => {
  const backInfo = useBackNavigationInfo();
  return {
    to: backInfo.link ?? '/challenges',
    label: backInfo.linkLabel ?? 'Wyzwania',
  };
};

export const useMarkVisited = (id: ChallengeId, stepId?: ChallengeStepId) => useEffect(
  () => {
    if (id == null) {
      return;
    }
    ChallengessService
      .markChallengeVisited(id)
      .catch(e => ErrorReportingService.report(e));
  },
  [id, stepId], // Note: this event should be re-submitted on entering a challenge step
);
